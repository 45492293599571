<template>
    <div class="create-address-box" id="create-address">
        <nav-index :title="$route.query.text ? $route.query.text  :'创建地址'" @handleDelete="handleDelete"/>
        <div class="card">
            <div class="top">
                <van-field v-model="addressInfo.name" label="收货人" placeholder="请输入姓名" required :error-message="errorMessage.name"/>
                <van-field v-model="addressInfo.phone" type="digit" label="手机号" placeholder="请输入手机号" required :error-message="errorMessage.phone"/>
                <van-field
                    readonly
                    clickable
                    name="area"
                    :value="addressInfo.area"
                    label="选择地区"
                    placeholder="省、市、区"
                    @click="showArea = true"
                    required :error-message="errorMessage.area"
                />
                <van-field  type="textarea" v-model="addressInfo.addr" label="详细地址" placeholder="如街道、小区、楼栋、门牌号等" required :error-message="errorMessage.addr" />
            </div>
            <div class="middle">
                <van-field name="checkboxGroup" label="复选框组">
                    <template #input>
                        <van-checkbox-group v-model="addressInfo.checkboxGroup" direction="horizontal" @change="groupChange">
                            <van-checkbox name="家" shape="square" >家</van-checkbox>
                            <van-checkbox name="公司" shape="square" >公司</van-checkbox>
                            <van-checkbox name="学校" shape="square" >学校</van-checkbox>
                        </van-checkbox-group>
                    </template>
                </van-field>
                <van-field name="switch" label="设为默认地址" input-align="right">
                    <template #input>
                        <van-switch v-model="addressInfo.switchChecked" size="25" />
                    </template>
                </van-field>
            </div>
            <div class="save-address">
                <div class="button" @click="saveAdderss">保存地址</div>
            </div>
        </div>
        <van-popup v-model="showArea" position="bottom">
            <van-area
                :area-list="areaList"
                @confirm="onConfirm"
                @cancel="showArea = false"
            />
        </van-popup>
        <Confirm ref="confirm" @handleDelete="deleteArrdress"/>
    </div>
</template>

<script>
import NavIndex from '../../components/nav/index.vue'
import areaList from '../../assets/js/areaList.js'
import { createAddress, updateAddressByAid, deleteAddressByAid } from '@/api/api'
import Confirm from '../../components/dialog/Confirm.vue'

export default {
    name: 'CreateAddress',
    components: {
      NavIndex,
        Confirm
    },
    created(){
        if(sessionStorage.getItem('editAddress') && this.$route.query.text){
            let editAddress = JSON.parse(sessionStorage.getItem('editAddress'))
            this.addressInfo.aid = editAddress.id
            this.addressInfo.name = editAddress.name
            this.addressInfo.phone = editAddress.phone
            this.addressInfo.area = editAddress.area
            this.addressInfo.addr = editAddress.desc
            this.addressInfo.switchChecked = Boolean(editAddress.defaultAddress)
            this.addressInfo.checkboxGroup = [editAddress.type]
        }
    },
    mounted(){
    },
    destroyed(){
        if(sessionStorage.getItem('editAddress')){
            sessionStorage.removeItem('editAddress')
        }
        if(this.addTimer) {
            clearTimeout(this.addTimer)
        }
    },
    data:()=>{
      return {
        showArea: false,
        areaList: areaList,
        addressInfo:{
            aid:'',
            name:'',
            phone: '',
            area:'',
            addr:'',
            checkboxGroup:[],
            switchChecked: false
        },
        errorMessage:{
            name:'',
            phone:'',
            area:'',
            addr:'',
        },
        verifySuccess: false,
        userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {}
      }
    },
    methods:{
        saveAdderss(){
            this.verifyFormInfo()
            if(this.verifySuccess){
                let params = {}
                params.uid = this.userInfo.uid
                params.nickname = this.addressInfo.name
                params.phone = this.addressInfo.phone
                params.area = this.addressInfo.area
                params.desc = this.addressInfo.addr
                params.type = this.addressInfo.checkboxGroup[0]
                params.default = this.addressInfo.switchChecked
                if(this.$route.query.aid){
                    params.aid = this.$route.query.aid
                    updateAddressByAid(params).then(()=>{
                        this.$notify({
                            message: '更新成功',
                            duration: 1000,
                            background: '#ec745b'
                        })
                        if(this.$route.query.from){
                            this.$router.push({ path:'/address', query:{ aid: 'xx', from: this.$route.query.from } })
                        } else {
                            if(this.$route.query.oid){
                                this.$router.push({ path:'/address', query:{ aid: 'xx', oid: this.$route.query.oid } })
                            } else {
                                this.$router.push({ path:'/address', query:{ aid: 'xx' } })
                            }
                        }
                    })
                } else {
                    createAddress(params).then(()=>{
                        this.$notify({
                            message: '创建成功',
                            duration: 1000,
                            background: '#ec745b'
                        })
                        setTimeout(()=>{
                            if(this.$route.query.from){
                                this.$router.push({ path:'/address', query:{ aid: 'xx', from: this.$route.query.from } })
                            } else {
                                if(this.$route.query.oid){
                                    this.$router.push({ path:'/address', query:{ aid: 'xx', oid: this.$route.query.oid } })
                                } else {
                                    this.$router.push({ path:'/address', query:{ aid: 'xx' } })
                                }
                            }
                        }, 300)
                    })
                }
            }
        },
        onConfirm(values) {
            this.addressInfo.area = values.filter((item) => !!item).map((item) => item.name).join(' ');
            this.showArea = false;
        },
        verifyFormInfo(){
            this.errorMessage.name = this.addressInfo.name.length === 0 ? '请输入姓名' :''
            this.errorMessage.phone = this.addressInfo.phone.length === 0 ? '请输入手机号' :''
            this.errorMessage.area = this.addressInfo.area.length === 0 ? '请选择地区' :''
            this.errorMessage.addr = this.addressInfo.addr.length === 0 ? '请输入详细地址' :''
            if(this.addressInfo.phone){
                let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                if(!reg_tel.test(this.addressInfo.phone)){
                      this.verifySuccess = false
                      this.$notify({
                          message: '手机号格式不正确',
                          duration: 1000,
                          background: '#ec745b'
                      })
                } else {
                    if(this.addressInfo.name.length > 0 && this.addressInfo.area.length > 0 && this.addressInfo.addr.length > 0){
                        this.verifySuccess = true
                    }
                }
            }
        },
        groupChange(val){
            if(val.length > 1){
                this.addressInfo.checkboxGroup.shift()
            }
        },
        handleDelete(){
            this.$refs.confirm.show('adderss')
        },
        deleteArrdress(){
            this.$refs.confirm.showLoading()
            deleteAddressByAid({ aid: this.$route.query.aid, uid: this.userInfo.uid }).then(()=>{
                this.$refs.confirm.showLoading()
                this.$refs.confirm.show()
                this.addTimer = setTimeout(()=>{
                    if(this.$route.query.from){
                        this.$router.push({ path:'/address', query:{ aid: 'xx', from:'personal' } })
                    } else {
                        if(this.$route.query.oid){
                            this.$router.push({ path:'/address', query:{ aid: 'xx', oid: this.$route.query.oid } })
                        } else {
                            this.$router.push({ path:'/address', query:{ aid: 'xx' } })
                        }
                    }
                }, 300)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.create-address-box {
    height: calc(100vh - 50px);
    padding-top: 50px;
    .card {
        padding: 15px 15px 15px;
        .top{

        }
        .middle {
            margin: 10px 0;
        }
    }
    .save-address {
        display: flex;
        justify-content: center;
        margin: 30px 0 10px;
        .button {
            height: 38px;
            width: 80%;
            display: flex;
            line-height: 30px;
            border-radius: 30px;
            align-items: center;
            font-size: 14px;
            color: #fff;
            background: #ec745b;
            justify-content: center;
            border: 1px solid #ec745b;
            cursor: pointer;
        }
    }
}
::v-deep .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #ec745b;
    border-color: #ec745b;
}
::v-deep .van-switch--on {
    background-color: #ec745b;
}
::v-deep .van-cell {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 16px;
    line-height: 24px;
    background-color: #fff;
}
::v-deep .van-field__label {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    box-sizing: border-box;
    width: 6.2em;
    margin-right: 0px;
    color: #646566;
    text-align: left;
    word-wrap: break-word;
}
::v-deep .van-popup {
    position: fixed;
    max-height: 100%;
    overflow-y: auto;
    background-color: #fff;
    max-width: 625px;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    -webkit-overflow-scrolling: touch;
}
</style>